<template>
  <ListAssetUtilizationCategoriesStyled>
    <PanelTableMolecule
      :isLoading="loading"
      :count="totalCount"
      :title="$tc('assetUtilizationCategory', 2) | capitalize"
      :columns="columns"
      :rows="tableData"
      :pageIndex="page + 1"
      :pageSize="pageSize"
      :createRouteName="'createAssetUtilizationCategory'"
      @pageNumberChange="pageNumberChange($event)"
      @reload="reload"
    />
    <router-view @reload="reload" />
  </ListAssetUtilizationCategoriesStyled>
</template>

<script>
import { styled } from '@egoist/vue-emotion'
import get from 'lodash/get'

import PanelTableMolecule from '@/components/Atomic/Molecules/PanelTableMolecule'
import ASSET_UTILIZATION_CATEGORIES_QUERY from '#/graphql/assetUtilizationCategories/assetUtilizationCategories.gql'
const ListAssetUtilizationCategoriesStyled = styled('div')`
  margin: 1rem;
`

export default {
  components: {
    ListAssetUtilizationCategoriesStyled,
    PanelTableMolecule,
  },
  data() {
    return {
      loading: true,
      totalCount: 0,
      models: [],
      errors: [],
      searchQuery: '',
      where: null,
      pageSize: 10,
      page: 0,
    }
  },
  computed: {
    columns() {
      const header = [
        { field: 'assetUtilizationCategoryId', key: 'assetUtilizationCategoryId', title: 'ID', align: 'left' },
        { field: 'name', key: 'name', title: 'name', align: 'center' },
        { field: 'assignedMachinesCount', key: 'assignedMachinesCount', title: 'Assets', align: 'center' },
      ]
      return header
    },
    tableData() {
      const tableData = this.models.map(assetUtilizationCategories => {
        return {
          assetUtilizationCategoryId: assetUtilizationCategories.id,
          name: assetUtilizationCategories.name,
          assignedMachinesCount: assetUtilizationCategories?.assignedMachines?.length,
          rowKey: assetUtilizationCategories.id,
        }
      })
      return tableData
    },
    first() {
      return this.page * this.pageSize
    },
    last() {
      return this.first + (this.pageSize - 1)
    },
  },
  methods: {
    reload() {
      this.$apollo.queries.models.refetch()
      this.$apollo.queries.assetUtilizationCategories.refetch()
    },
    pageNumberChange(pageIndex) {
      this.page = pageIndex - 1
    },
  },
  apollo: {
    models: {
      query: ASSET_UTILIZATION_CATEGORIES_QUERY,
      variables() {
        return {
          cursor: {
            first: this.first,
            last: this.last,
          },
        }
      },
      manual: true,
      result({ data, loading }) {
        this.loading = loading
        this.models = get(data, 'assetUtilizationCategories.enumValues', []).map((category, index) => ({
          name: category.name,
          id: index++,
        }))
        this.count = this.models.length
      },
    },
    assetUtilizationCategories: {
      query: ASSET_UTILIZATION_CATEGORIES_QUERY,
      update({ assetUtilizationCategories }) {
        this.totalCount = assetUtilizationCategories?.enumValues?.length
      },
    },
  },
}
</script>
